import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <footer className="footer-footer">
      <div className="footer-container1">
        <div className="footer-container2">
          <Link to="/" className="footer-navlink1">
            <img
              alt={props.imageAlt}
              src={props.imageSrc}
              className="footer-image"
            />
          </Link>
          <span>Copyright © 2024 aiply.ai</span>
        </div>
        <div className="footer-container3">
          <div className="footer-container4">
            <span className="footer-text2 Large">Company</span>
            <Link to="/about-us" className="footer-navlink2 Large">
              About Us
            </Link>
            <a
              href="https://blog.aiply.ai"
              target="_blank"
              rel="noreferrer noopener"
              className="footer-link1 Large"
            >
              Blog
            </a>
            <Link to="/contact-us" className="footer-navlink3">
              Contact us
            </Link>
          </div>
          <div className="footer-container5">
            <span className="footer-text3 Large">Help &amp; Support</span>
            <a href="mailto:support@aiply.ai?subject=" className="footer-link2">
              Contact support
            </a>
          </div>
          <div className="footer-container6">
            <span className="footer-text4 Large">Legal</span>
            <Link to="/terms-of-use" className="footer-link3">
              Terms &amp; Conditions
            </Link>
            <Link to="/privacy-policy" className="footer-link4">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  imageSrc: '/logos/aiply_icon.svg',
  imageAlt: 'image',
}

Footer.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
}

export default Footer
