import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import PrimaryPinkButton from './primary-pink-button'
import './privacy.css'

const Privacy = (props) => {
  return (
    <div
      id={props.features2Id}
      className="privacy-layout302 thq-section-padding"
    >
      <div className="privacy-max-width thq-section-max-width">
        <div className="privacy-section-title thq-flex-column">
          <h2 className="HeadingOne header-gradient">
            {props.sectionTitle ?? (
              <Fragment>
                <span className="privacy-text16">
                  Your privacy is guaranteed
                </span>
              </Fragment>
            )}
          </h2>
        </div>
        <div className="privacy-content1">
          <div className="privacy-row thq-grid-5">
            <div className="privacy-feature1 thq-flex-column">
              <img
                alt={props.feature1ImageAlt}
                src={props.feature1ImageSrc}
                className="privacy-image1 thq-team-image-round"
              />
              <div className="thq-flex-column">
                <h3 className="privacy-title11 thq-heading-3">
                  {props.feature1Title ?? (
                    <Fragment>
                      <span className="privacy-text11">
                        Privacy first: no recordings, instant processing
                      </span>
                    </Fragment>
                  )}
                </h3>
                <span className="privacy-description11 thq-body-small">
                  {props.feature1Description ?? (
                    <Fragment>
                      <span className="privacy-text12">
                        <span>
                          At Aiply, your privacy is a top priority. We do not
                          record any interviews or responses during assessments.
                          All data is processed immediately on our secure
                          servers, so there is no stored data beyond the
                          assessment itself. This ensures that every interaction
                          remains private and temporary, giving developers and
                          companies peace of mind that their personal and
                          professional information stays confidential.
                        </span>
                        <br></br>
                        <br></br>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
            <div className="privacy-feature3 thq-flex-column">
              <img
                alt={props.feature3ImageAlt}
                src={props.feature3ImageSrc}
                className="privacy-image2 thq-team-image-round"
              />
              <div className="thq-flex-column">
                <h3 className="privacy-title12 thq-heading-3">
                  {props.feature3Title ?? (
                    <Fragment>
                      <span className="privacy-text18">
                        <span>
                          Robust security protocols for safe data transmission
                        </span>
                        <br></br>
                      </span>
                    </Fragment>
                  )}
                </h3>
                <span className="privacy-description12 thq-body-small">
                  {props.feature3Description ?? (
                    <Fragment>
                      <span className="privacy-text17">
                        Aiply employs advanced security protocols to protect
                        your data at every step. Using industry-standard
                        encryption and secure transmission channels, all
                        information is securely handled from the moment it’s
                        sent to our servers. We continuously monitor and update
                        our security measures, ensuring that any data exchanged
                        remains protected against unauthorized access and
                        securely handled throughout the process.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="privacy-actions">
            <PrimaryPinkButton
              button1="Get started now"
              button2="get started now"
              rootClassName="primary-pink-buttonroot-class-name"
            ></PrimaryPinkButton>
          </div>
        </div>
      </div>
    </div>
  )
}

Privacy.defaultProps = {
  feature1Title: undefined,
  feature1Description: undefined,
  sectionTitle: undefined,
  feature3Description: undefined,
  feature1ImageAlt: 'AI-Driven Interviews Image',
  features2Id: '',
  feature3ImageSrc:
    'https://images.unsplash.com/photo-1515191107209-c28698631303?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTgwOTg1Mnw&ixlib=rb-4.0.3&q=80&w=1080',
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1710993011349-b3001adc9f00?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyOTgwOTg1Mnw&ixlib=rb-4.0.3&q=80&w=1080',
  feature3Title: undefined,
  feature3ImageAlt: 'Social Conversations Data Image',
}

Privacy.propTypes = {
  feature1Title: PropTypes.element,
  feature1Description: PropTypes.element,
  sectionTitle: PropTypes.element,
  feature3Description: PropTypes.element,
  feature1ImageAlt: PropTypes.string,
  features2Id: PropTypes.string,
  feature3ImageSrc: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  feature3Title: PropTypes.element,
  feature3ImageAlt: PropTypes.string,
}

export default Privacy
