// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.features-features {
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  max-width: 1320px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
}
@media(max-width: 991px) {
  .features-features {
    max-width: 960px;
  }
}
@media(max-width: 767px) {
  .features-features {
    max-width: 720px;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-unitandahalfunit);
    padding-right: var(--dl-space-space-unitandahalfunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
}
@media(max-width: 479px) {
  .features-features {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/features.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,2CAA2C;EAC3C,8CAA8C;EAC9C,+CAA+C;EAC/C,mBAAmB;EACnB,8CAA8C;AAChD;AACA;EACE;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,gBAAgB;IAChB,6CAA6C;IAC7C,oDAAoD;IACpD,qDAAqD;IACrD,gDAAgD;EAClD;AACF;AACA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".features-features {\n  width: 100%;\n  display: flex;\n  position: relative;\n  flex-wrap: wrap;\n  max-width: 1320px;\n  align-items: flex-start;\n  padding-top: var(--dl-space-space-sixunits);\n  padding-left: var(--dl-space-space-tripleunit);\n  padding-right: var(--dl-space-space-tripleunit);\n  flex-direction: row;\n  padding-bottom: var(--dl-space-space-sixunits);\n}\n@media(max-width: 991px) {\n  .features-features {\n    max-width: 960px;\n  }\n}\n@media(max-width: 767px) {\n  .features-features {\n    max-width: 720px;\n    padding-top: var(--dl-space-space-doubleunit);\n    padding-left: var(--dl-space-space-unitandahalfunit);\n    padding-right: var(--dl-space-space-unitandahalfunit);\n    padding-bottom: var(--dl-space-space-doubleunit);\n  }\n}\n@media(max-width: 479px) {\n  .features-features {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
