// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outline-purple-button-container {
  display: flex;
  position: relative;
}
.outline-purple-button-button {
  color: var(--dl-color-theme-secondary2);
  cursor: pointer;
  outline: none;
  min-width: 150px;
  transition: 0.3s;
  padding-top: var(--dl-space-space-triplequarterunit);
  border-color: var(--dl-color-primary-500);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius75);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-triplequarterunit);
  background-color: var(--dl-color-primary-700);
}
.outline-purple-button-button:hover {
  transform: scale(1.02);
}
`, "",{"version":3,"sources":["webpack://./src/components/outline-purple-button.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,uCAAuC;EACvC,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,oDAAoD;EACpD,yCAAyC;EACzC,6CAA6C;EAC7C,+CAA+C;EAC/C,8CAA8C;EAC9C,uDAAuD;EACvD,6CAA6C;AAC/C;AACA;EACE,sBAAsB;AACxB","sourcesContent":[".outline-purple-button-container {\n  display: flex;\n  position: relative;\n}\n.outline-purple-button-button {\n  color: var(--dl-color-theme-secondary2);\n  cursor: pointer;\n  outline: none;\n  min-width: 150px;\n  transition: 0.3s;\n  padding-top: var(--dl-space-space-triplequarterunit);\n  border-color: var(--dl-color-primary-500);\n  padding-left: var(--dl-space-space-fourunits);\n  border-radius: var(--dl-radius-radius-radius75);\n  padding-right: var(--dl-space-space-fourunits);\n  padding-bottom: var(--dl-space-space-triplequarterunit);\n  background-color: var(--dl-color-primary-700);\n}\n.outline-purple-button-button:hover {\n  transform: scale(1.02);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
