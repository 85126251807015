import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer-gray.css'

const FooterGray = (props) => {
  return (
    <footer className="footer-gray-footer">
      <div className="footer-gray-divider"></div>
      <div className="footer-gray-container1">
        <div className="footer-gray-container2">
          <Link to="/" className="footer-gray-navlink1">
            <div className="footer-gray-container3">
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="footer-gray-image"
              />
              <span className="footer-gray-text1">AIPLY.AI</span>
            </div>
          </Link>
          <div className="footer-gray-container4">
            <div className="footer-gray-container5">
              <span className="footer-gray-text2 Large">Company</span>
              <Link to="/about-us" className="footer-gray-navlink2 Large">
                About Us
              </Link>
              <a
                href="https://blog.aiply.ai"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-gray-text3 Large"
              >
                Blog
              </a>
            </div>
            <div className="footer-gray-container6">
              <span className="footer-gray-text4 Large">
                Help &amp; Support
              </span>
              <a
                href="mailto:support@aiply.ai?subject="
                className="footer-gray-link Large"
              >
                Contact support
              </a>
            </div>
            <div className="footer-gray-container7">
              <span className="footer-gray-text5 Large">Legal</span>
              <Link to="/terms-of-use" className="footer-gray-text6 Large">
                Terms &amp; Conditions
              </Link>
              <Link to="/privacy-policy" className="footer-gray-text7 Large">
              Privacy Policy
              </Link>
            </div>
          </div>
        </div>
        <span className="footer-gray-text8 Small">
          All rights reserved. Copyright © 2024 aiply.ai
        </span>
      </div>
    </footer>
  )
}

FooterGray.defaultProps = {
  imageAlt: 'image',
  imageSrc: '/logos/aiply_icon.svg',
}

FooterGray.propTypes = {
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
}

export default FooterGray
