import React, { Fragment, useLayoutEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Helmet } from 'react-helmet';

import Header from '../components/header';
import FooterGray from '../components/footer-gray';
import './about-us.css';

const AboutUs = (props) => {
  

  return (
    <div className="about-us-container1">
      <Helmet>
        <title>About-Us - AIPLY.AI — Your personal technical interview co-pilot</title>
        <meta
          name="description"
          content="Prepare for your next technical interview with AI-powered mock interviews. Practice with realistic scenarios, get instant feedback, and track your progress to improve your skills. Start for free at aiply.ai"
        />
        <meta
          property="og:title"
          content="About-Us - AIPLY.AI — Your personal technical interview co-pilot"
        />
        <meta
          property="og:description"
          content="Prepare for your next technical interview with AI-powered mock interviews. Practice with realistic scenarios, get instant feedback, and track your progress to improve your skills. Start for free at aiply.ai"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/ca824307-f5ae-4ab7-98e5-9c159ebfe20c/536063ba-8d28-42f7-affc-1ead157ec42c?org_if_sml=1&force_format=original"
        />
      </Helmet>
      <Header
        link={
          <Fragment>
            <span className="about-us-text1">
              <span className="Large">AIPLY.AI</span>
              <br />
            </span>
          </Fragment>
        }
      />
      <div className="about-us-container2">
        <div className="about-us-main">
          <Player
            src="https://lottie.host/9c9d1be8-b69c-4ba6-8505-cc4c20783e86/NHx2cbfBaL.json"
            loop
            speed={1}
            autoplay
            background="transparent"
            className="about-us-lottie-node"
          />
          <div className="about-us-container3">
            <h1 className="about-us-text4 HeadingOne">You Work With</h1>
            <h2 className="about-us-text5">
              a team with deep experience in tech, hiring, and AI-driven solutions.
            </h2>
            <p className="about-us-text6">
              Our co-founders have spent years working in software development
              and talent acquisition, both feeling the challenges of finding
              skilled candidates quickly and fairly. With backgrounds spanning
              roles in major tech firms and startups, they saw the potential of
              AI to streamline and elevate the hiring process for companies and
              candidates alike. Driven by a commitment to simplify and improve
              how tech talent is discovered, Aiply was created to bridge gaps in
              traditional hiring practices with intelligent, data-backed
              assessments.
            </p>
            
          </div>
        </div>
      </div>
      <FooterGray />
    </div>
  );
};

export default AboutUs;
