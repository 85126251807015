// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-blue-button-container {
  display: flex;
  position: relative;
}
.primary-blue-button-button {
  color: var(--dl-color-gray-white);
  outline: none;
  background: linear-gradient(310deg,#2152ff,#21d4fd);
  box-shadow: 0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%);
  transition: all .15s ease-in;
  padding-top: var(--dl-space-space-triplequarterunit);
  border-width: 0px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  border-radius: 1.875rem;
  padding-right: var(--dl-space-space-unitandahalfunit);
  padding-bottom: var(--dl-space-space-triplequarterunit);
}
.primary-blue-button-button:hover {
  cursor: pointer;
  transform: scale(1.02);
}
`, "",{"version":3,"sources":["webpack://./src/components/primary-blue-button.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,iCAAiC;EACjC,aAAa;EACb,mDAAmD;EACnD,2EAA2E;EAC3E,4BAA4B;EAC5B,oDAAoD;EACpD,iBAAiB;EACjB,oDAAoD;EACpD,uBAAuB;EACvB,qDAAqD;EACrD,uDAAuD;AACzD;AACA;EACE,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".primary-blue-button-container {\n  display: flex;\n  position: relative;\n}\n.primary-blue-button-button {\n  color: var(--dl-color-gray-white);\n  outline: none;\n  background: linear-gradient(310deg,#2152ff,#21d4fd);\n  box-shadow: 0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%);\n  transition: all .15s ease-in;\n  padding-top: var(--dl-space-space-triplequarterunit);\n  border-width: 0px;\n  padding-left: var(--dl-space-space-unitandahalfunit);\n  border-radius: 1.875rem;\n  padding-right: var(--dl-space-space-unitandahalfunit);\n  padding-bottom: var(--dl-space-space-triplequarterunit);\n}\n.primary-blue-button-button:hover {\n  cursor: pointer;\n  transform: scale(1.02);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
