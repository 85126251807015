// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-card-container {
  flex: 0 0 auto;
  width: 25%;
  display: flex;
  margin-top: var(--dl-space-space-triplequarterunit);
  align-items: center;
  padding-left: var(--dl-space-space-triplequarterunit);
  padding-right: var(--dl-space-space-triplequarterunit);
  flex-direction: column;
}
.feature-card-image {
  height: 60px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unitandahalfunit);
}
.feature-card-text1 {
  fill: var(--dl-color-primary-100);
  color: var(--dl-color-primary-100);
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.feature-card-text2 {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 767px) {
  .feature-card-container {
    width: 50%;
  }
}
@media(max-width: 479px) {
  .feature-card-container {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/feature-card.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,UAAU;EACV,aAAa;EACb,mDAAmD;EACnD,mBAAmB;EACnB,qDAAqD;EACrD,sDAAsD;EACtD,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,iBAAiB;EACjB,qDAAqD;AACvD;AACA;EACE,iCAAiC;EACjC,kCAAkC;EAClC,0CAA0C;EAC1C,kBAAkB;EAClB,6CAA6C;AAC/C;AACA;EACE,sCAAsC;EACtC,uCAAuC;EACvC,kBAAkB;EAClB,yCAAyC;AAC3C;AACA;EACE;IACE,UAAU;EACZ;AACF;AACA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".feature-card-container {\n  flex: 0 0 auto;\n  width: 25%;\n  display: flex;\n  margin-top: var(--dl-space-space-triplequarterunit);\n  align-items: center;\n  padding-left: var(--dl-space-space-triplequarterunit);\n  padding-right: var(--dl-space-space-triplequarterunit);\n  flex-direction: column;\n}\n.feature-card-image {\n  height: 60px;\n  object-fit: cover;\n  margin-bottom: var(--dl-space-space-unitandahalfunit);\n}\n.feature-card-text1 {\n  fill: var(--dl-color-primary-100);\n  color: var(--dl-color-primary-100);\n  margin-top: var(--dl-space-space-halfunit);\n  text-align: center;\n  margin-bottom: var(--dl-space-space-halfunit);\n}\n.feature-card-text2 {\n  fill: var(--dl-color-theme-secondary2);\n  color: var(--dl-color-theme-secondary2);\n  text-align: center;\n  margin-bottom: var(--dl-space-space-unit);\n}\n@media(max-width: 767px) {\n  .feature-card-container {\n    width: 50%;\n  }\n}\n@media(max-width: 479px) {\n  .feature-card-container {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
