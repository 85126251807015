// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-item-container {
  flex: 0 0 auto;
  display: flex;
  padding: 1rem;
  max-width: 500px;
  align-items: flex-start;
  flex-direction: column;
}
.list-item-text1 {
  color: var(--dl-color-theme-accent1);
  margin-bottom: var(--dl-space-space-halfunit);
}
.list-item-text2 {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  font-family: "Plus Jakarta Sans";
}
`, "",{"version":3,"sources":["webpack://./src/components/list-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,uBAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,oCAAoC;EACpC,6CAA6C;AAC/C;AACA;EACE,sCAAsC;EACtC,uCAAuC;EACvC,gCAAgC;AAClC","sourcesContent":[".list-item-container {\n  flex: 0 0 auto;\n  display: flex;\n  padding: 1rem;\n  max-width: 500px;\n  align-items: flex-start;\n  flex-direction: column;\n}\n.list-item-text1 {\n  color: var(--dl-color-theme-accent1);\n  margin-bottom: var(--dl-space-space-halfunit);\n}\n.list-item-text2 {\n  fill: var(--dl-color-theme-secondary2);\n  color: var(--dl-color-theme-secondary2);\n  font-family: \"Plus Jakarta Sans\";\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
