import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import Header from '../components/header';
import FooterGray from '../components/footer-gray';
import './terms-of-use.css';

const TermsOfUse = () => {
  

  return (
    <div className="terms-of-use-container1">
      <Helmet>
        <title>Terms of Use - AIPLY.AI — Your personal technical interview co-pilot</title>
        <meta
          name="description"
          content="Prepare for your next technical interview with AI-powered mock interviews. Practice with realistic scenarios, get instant feedback, and track your progress to improve your skills. Start for free at aiply.ai"
        />
        <meta
          property="og:title"
          content="About-Us - AIPLY.AI — Your personal technical interview co-pilot"
        />
        <meta
          property="og:description"
          content="Prepare for your next technical interview with AI-powered mock interviews. Practice with realistic scenarios, get instant feedback, and track your progress to improve your skills. Start for free at aiply.ai"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/ca824307-f5ae-4ab7-98e5-9c159ebfe20c/536063ba-8d28-42f7-affc-1ead157ec42c?org_if_sml=1&force_format=original"
        />
      </Helmet>
      
      <Header
        link={
          <span className="terms-of-use-text1">
            <span className="Large">AIPLY.AI</span>
            <br />
          </span>
        }
      />

<div>
<div className="terms-of-use-container2">
<div>
  <h1>Terms of Use</h1>
  <p>Last updated: 11/7/2024</p>

  <h2>Acceptance of Terms</h2>
  <p>By using Aiply.ai ("we", "us", or "our") and its AI-driven talent assessment services, you agree to these Terms of Use. Please read carefully. If you disagree with these terms, refrain from using our services.</p>

  <h2>1. Service Overview</h2>
  <p>Aiply.ai provides AI-powered skill assessments, mock interviews, and talent-matching solutions for individuals and companies. Our services aim to support talent discovery, shadow talent identification, and skill development.</p>

  <h2>2. Account Registration</h2>
  <p>When creating an account, you agree to provide accurate information. You are responsible for keeping your login credentials secure and are liable for any activities on your account.</p>

  <h2>3. Intellectual Property</h2>
  <p>All content and software on Aiply.ai, including text, graphics, and functionality, is owned by us or our licensors. Unauthorized reproduction or distribution is prohibited.</p>

  <h2>4. Permitted Use</h2>
  <p>You agree to use Aiply.ai only for lawful purposes, including personal skill assessment and corporate talent evaluation, and not for any prohibited or harmful activities.</p>

  <h2>5. Payment and Subscription</h2>
  <p>Subscriptions for companies and private interview services are available under different plans. Fees are billed monthly or annually as specified in your selected plan. You are responsible for paying any applicable fees on time.</p>

  <h2>6. Termination</h2>
  <p>We reserve the right to suspend or terminate your account if you violate these terms. Upon termination, your access to our services will end immediately.</p>

  <h2>7. Limitation of Liability</h2>
  <p>Aiply.ai is not liable for any indirect, incidental, or consequential damages resulting from your use of our services. Our liability is limited to the amount you paid for services within the last year.</p>

  <h2>8. Governing Law</h2>
  <p>These terms are governed by the laws of United States. You agree to the jurisdiction of United Sates courts for any disputes.</p>

  <h2>9. Changes to Terms</h2>
  <p>We may modify these Terms of Use as necessary. Continued use of our services after changes are made indicates acceptance of the updated terms.</p>

  <h2>10. Contact Us</h2>
  <p>For questions about these Terms of Use, contact us at <a href="mailto:info@aiply.ai">info@aiply.ai</a>.</p>
</div>

</div>
</div>
 

      <FooterGray />
    </div>
  );
};

export default TermsOfUse;
