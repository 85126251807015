import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import './style.css';
import AboutUs from './views/about-us';
import ContactUs from './views/contact-us';
import Home from './views/home';
import NotFound from './views/not-found';
import PrivacyPolicy from './views/privacy-policy';
import TermsOfUse from './views/terms-of-use';
import ScrollToTop from './components/ScrollToTop'; // Import ScrollToTop component


const resizeObserverLoopErr = "ResizeObserver loop limit exceeded";
window.addEventListener("error", (e) => {
  if (e.message === resizeObserverLoopErr) {
    e.stopImmediatePropagation();
  }
});

const App = () => {
  return (
    <Router>
      <ScrollToTop /> {/* Add the ScrollToTop component here */}
      <Switch>
        <Route component={AboutUs} exact path="/about-us" />
        <Route component={ContactUs} exact path="/contact-us" />
        <Route component={Home} exact path="/" />
        <Route component={PrivacyPolicy} exact path="/privacy-policy" />
        <Route component={TermsOfUse} exact path="/terms-of-use" />
        <Route component={NotFound} path="*" />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
