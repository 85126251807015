import React from 'react'

import PropTypes from 'prop-types'

import './outline-purple-button.css'

const OutlinePurpleButton = (props) => {
  return (
    <div className="outline-purple-button-container">
      <button className="outline-purple-button-button ButtonSmall button">
        {props.button}
      </button>
    </div>
  )
}

OutlinePurpleButton.defaultProps = {
  button: 'Button',
}

OutlinePurpleButton.propTypes = {
  button: PropTypes.string,
}

export default OutlinePurpleButton
