import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import HeaderFull from '../components/header-full';
import PrimaryBlueButton from '../components/primary-blue-button';
import Footer from '../components/footer';
import './contact-us.css';

const ContactUs = (props) => {
  const [formData, setFormData] = useState({
    firstName: '',
    surname: '',
    email: '',
    company: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Simulating successful form submission
      toast.success('Your message has been sent successfully!');
      setFormData({ firstName: '', surname: '', email: '', company: '', message: '' }); // Clear form after submission
    } catch (error) {
      console.error(error);
      toast.error('There was an error sending your message. Please try again later.');
    }
  };

  return (
    <div className="contact-us-container1">
      <Helmet>
        <title>
          Contact Us - AIPLY.AI — Your personal technical interview co-pilot
        </title>
        <meta
          name="description"
          content="Prepare for your next technical interview with AI-powered mock interviews. Practice with realistic scenarios, get instant feedback, and track your progress to improve your skills. Start for free at aiply.ai"
        />
        <meta
          property="og:title"
          content="Contact Us - AIPLY.AI — Your personal technical interview co-pilot"
        />
        <meta
          property="og:description"
          content="Prepare for your next technical interview with AI-powered mock interviews. Practice with realistic scenarios, get instant feedback, and track your progress to improve your skills. Start for free at aiply.ai"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/ca824307-f5ae-4ab7-98e5-9c159ebfe20c/536063ba-8d28-42f7-affc-1ead157ec42c?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <HeaderFull
        link={
          <Fragment>
            <span className="contact-us-text10">
              <span className="Large">AIPLY.AI</span>
              <br />
            </span>
          </Fragment>
        }
      />
      <div className="contact-us-contact">
        <div className="contact-us-container2">
          <div className="contact-us-form1">
            <h2 className="contact-us-text13 HeadingOne">
              <span>Say Hi!</span>
            </h2>
            <span className="contact-us-text15 Lead">
              We are excited to connect with you
            </span>
            <form className="contact-us-form2" onSubmit={handleSubmit}>
              <label className="contact-us-text16 Label">My name is*</label>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                className="contact-us-textinput1 Small input"
                required
              />
              <label className="contact-us-text17 Label">My surname is</label>
              <input
                type="text"
                name="surname"
                placeholder="Surname"
                value={formData.surname}
                onChange={handleChange}
                className="contact-us-textinput2 Small input"
              />
              <label className="contact-us-text18 Label">My email*</label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="contact-us-textinput3 Small input"
                required
              />
              <label className="contact-us-text19 Label">I&apos;m working for</label>
              <input
                type="text"
                name="company"
                placeholder="Your company's name"
                value={formData.company}
                onChange={handleChange}
                className="contact-us-textinput4 Small input"
              />
              <label className="contact-us-text20 Label">Your message</label>
              <textarea
                rows="2"
                name="message"
                placeholder="I want to say that..."
                value={formData.message}
                onChange={handleChange}
                className="contact-us-textarea textarea Small"
              />
              <div className="contact-us-container3">
                <div className="contact-us-container4">
                  <PrimaryBlueButton button="Send Message" />
                </div>
              </div>
            </form>
          </div>
          <div className="contact-us-info">
            <div className="contact-us-container5">
              <h3 className="HeadingTwo">
                <span>
                  Contact
                  <span dangerouslySetInnerHTML={{ __html: ' ' }} />
                </span>
                <span>Information</span>
              </h3>
              <span className="contact-us-text24">
                Fill up the form and our Team will get back to you within 24 hours.
              </span>
            </div>
            <img alt="Wave Graphic" src="/wave-1.svg" className="contact-us-image" />
            <div className="contact-us-container6"></div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default ContactUs;
