import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import PrimaryPinkButton from './primary-pink-button'
import './header-full.css'

const HeaderFull = (props) => {
  return (
    <div data-role="Header" className="header-full-header">
      <nav className="header-full-nav">
        <div className="header-full-container1">
          <Link to="/" className="header-full-navlink1">
            <div className="header-full-container2">
              <img
                alt={props.imageAlt1}
                src={props.imageSrc1}
                loading="lazy"
                className="header-full-image1"
              />
              <span className="header-full-text1">AIPLY.AI</span>
            </div>
          </Link>
          <div className="header-full-menu1">
            <Link to="/" className="header-full-navlink2 Large">
              Home
            </Link>
            <a
              href="https://blog.aiply.ai"
              target="_blank"
              rel="noreferrer noopener"
              className="header-full-link1 Large"
            >
              Blog
            </a>
            <Link to="/about-us" className="header-full-navlink2 Large">
              About us
            </Link>
          </div>
          <div className="header-full-container3">
            <a
              href="https://app.aiply.ai"
              target="_blank"
              rel="noreferrer noopener"
              className="header-full-link3"
            >
              <div className="header-full-container4">
                <PrimaryPinkButton button2="try now"></PrimaryPinkButton>
              </div>
            </a>
            <div data-role="BurgerMenu" className="header-burger-menu">
              <svg viewBox="0 0 1024 1024" className="header-icon1">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
          </div>
        </div>
      </nav>
      <div data-role="MobileMenu" className="header-full-mobile-menu">
        
          <div className="header-full-top">
            <div className="header-full-container5">
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                loading="lazy"
                className="header-full-image2"
              />
              <Link to="/">
              <span className="header-full-link4">
                {props.link ?? (
                  <Fragment>
                    <span className="header-full-text2">
                      <span className="Large">AIPLY.AI</span>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </span></Link>
            </div>
            <div data-role="CloseMobileMenu" className="header-full-close-menu">
              <svg viewBox="0 0 1024 1024" className="header-full-icon3">
                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
              </svg>
            </div>
          </div>
        
        <div className="header-full-mid">
          <div className="header-full-menu2">
            <Link to="/home" className="header-full-navlink4 Large">
              Home
            </Link>
            <a
              href="https://blog.aiply.ai"
              target="_blank"
              rel="noreferrer noopener"
              className="header-full-link5 Large"
            >
              Blog
            </a>
            <Link to="/about-us" className="header-full-navlink4 Large">
              About us
            </Link>
          </div>
        </div>
        <a
          href="https://app.aiply.ai"
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="header-full-bot">
            <PrimaryPinkButton
              button="buy now"
              button2="try now"
            ></PrimaryPinkButton>
          </div>
        </a>
      </div>
    </div>
  )
}

HeaderFull.defaultProps = {
  imageSrc1: '/logos/aiply_icon.svg',
  imageAlt1: 'aiply.ai logo',
  imageAlt: 'aiply.ai logo',
  imageSrc: '/logos/aiply_icon.svg',
  link: undefined,
}

HeaderFull.propTypes = {
  imageSrc1: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  link: PropTypes.element,
}

export default HeaderFull
