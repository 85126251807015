import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import Header from '../components/header';
import FooterGray from '../components/footer-gray';
import './privacy-policy.css';

const PrivacyPolicy = () => {
  

  return (
    <div className="privacy-policy-container1">
      <Helmet>
        <title>Privacy Policy - AIPLY.AI — Your personal technical interview co-pilot</title>
        <meta
          name="description"
          content="Prepare for your next technical interview with AI-powered mock interviews. Practice with realistic scenarios, get instant feedback, and track your progress to improve your skills. Start for free at aiply.ai"
        />
        <meta
          property="og:title"
          content="About-Us - AIPLY.AI — Your personal technical interview co-pilot"
        />
        <meta
          property="og:description"
          content="Prepare for your next technical interview with AI-powered mock interviews. Practice with realistic scenarios, get instant feedback, and track your progress to improve your skills. Start for free at aiply.ai"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/ca824307-f5ae-4ab7-98e5-9c159ebfe20c/536063ba-8d28-42f7-affc-1ead157ec42c?org_if_sml=1&force_format=original"
        />
      </Helmet>
      
      <Header
        link={
          <span className="privacy-policy-text1">
            <span className="Large">AIPLY.AI</span>
            <br />
          </span>
        }
      />

<div>
<div className="privacy-policy-container2">
  <h1>Privacy Policy</h1>
  <p>Last updated: 11/7/2024</p>

  <h2>Introduction</h2>
  <p>Welcome to Aiply.ai ("we", "us", or "our"). At Aiply.ai, we are committed to protecting your privacy and data. This Privacy Policy explains how we collect, use, and protect information when you use our AI-powered platform for skill assessments, mock interviews, and talent-matching services. </p>

  <h2>1. Information We Collect</h2>
  <p>We collect the following types of information:</p>
  <ul>
    <li><strong>Personal Information:</strong> This includes your name, email address, job title, company name, and other details provided during registration, account setup, or service use.</li>
    <li><strong>Usage Data:</strong> Information such as IP address, browser type, device details, and interactions with our site and platform to monitor usage, troubleshoot issues, and improve services.</li>
    <li><strong>Candidate Data:</strong> If you engage with our talent-matching and assessment services, we may collect information related to job skills, experience, and performance on assessments.</li>
  </ul>

  <h2>2. How We Use Your Information</h2>
  <p>We use your information for the following purposes:</p>
  <ul>
    <li>To provide, maintain, and enhance our services, including mock interviews, AI-driven skill assessments, and talent-matching solutions.</li>
    <li>To send updates, notifications, and important information about your account and our services.</li>
    <li>To analyze service usage and improve user experience through performance monitoring and feedback.</li>
    <li>To communicate with you and respond to questions or concerns about our services.</li>
  </ul>

  <h2>3. Data Sharing</h2>
  <p>We may share your data in limited cases:</p>
  <ul>
    <li>With trusted service providers to help us operate our business and deliver services.</li>
    <li>To comply with legal requirements, such as responding to court orders or legal processes.</li>
    <li>To protect our rights, property, and users' safety.</li>
  </ul>

  <h2>4. Cookie Declaration</h2>
  <p>We use cookies and similar tracking technologies to personalize your experience and gather usage data. We employ Cookiebot to manage consent for cookie usage. You can review our full Cookie Declaration below, which is updated regularly:</p>
  <div id="CookieDeclaration" class="cookiebot"><script id="CookieDeclaration" src="https://consent.cookiebot.com/7ca8740f-e2d8-45f4-ac38-c68956b85574/cd.js" type="text/javascript" async></script></div>
  

  <h2>5. Data Security</h2>
  <p>We implement reasonable security measures to protect your information. However, no transmission over the internet or method of storage is completely secure. We strive to keep your data safe but cannot guarantee absolute security.</p>

  <h2>6. Your Rights</h2>
  <p>You have rights regarding your data, including access, correction, deletion, and opting out of certain data uses. Contact us at <a href="mailto:info@aiply.ai">info@aiply.ai</a> to exercise these rights.</p>

  <h2>7. Changes to This Privacy Policy</h2>
  <p>We may update this Privacy Policy periodically. If changes are significant, we will notify you via email or our website.</p>

  <h2>8. Contact Us</h2>
  <p>If you have questions about this Privacy Policy or our practices, please reach out to <a href="mailto:info@aiply.ai">info@aiply.ai</a>.</p>
</div>
</div>
 

      <FooterGray />
    </div>
  );
};

export default PrivacyPolicy;
