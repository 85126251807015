// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outline-gray-button-container {
  display: flex;
  position: relative;
}
.outline-gray-button-button {
  color: var(--dl-color-secondary-200);
  outlinr: none;
  transition: all .15s ease-in;
  padding-top: var(--dl-space-space-triplequarterunit);
  border-color: var(--dl-color-secondary-200);
  padding-left: var(--dl-space-space-unitandahalfunit);
  border-radius: 1.875rem;
  padding-right: var(--dl-space-space-unitandahalfunit);
  padding-bottom: var(--dl-space-space-triplequarterunit);
}
.outline-gray-button-button:hover {
  cursor: pointer;
  transform: scale(1.02);
}
`, "",{"version":3,"sources":["webpack://./src/components/outline-gray-button.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,oCAAoC;EACpC,aAAa;EACb,4BAA4B;EAC5B,oDAAoD;EACpD,2CAA2C;EAC3C,oDAAoD;EACpD,uBAAuB;EACvB,qDAAqD;EACrD,uDAAuD;AACzD;AACA;EACE,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".outline-gray-button-container {\n  display: flex;\n  position: relative;\n}\n.outline-gray-button-button {\n  color: var(--dl-color-secondary-200);\n  outlinr: none;\n  transition: all .15s ease-in;\n  padding-top: var(--dl-space-space-triplequarterunit);\n  border-color: var(--dl-color-secondary-200);\n  padding-left: var(--dl-space-space-unitandahalfunit);\n  border-radius: 1.875rem;\n  padding-right: var(--dl-space-space-unitandahalfunit);\n  padding-bottom: var(--dl-space-space-triplequarterunit);\n}\n.outline-gray-button-button:hover {\n  cursor: pointer;\n  transform: scale(1.02);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
