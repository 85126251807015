import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import PrimaryPinkButton from './primary-pink-button'
import './header.css'

const Header = (props) => {
  return (
    <div data-role="Header" className={`header-header ${props.rootClassName} `}>
      <nav className="header-nav">
        <div className="header-container1">
          <Link to="/" className="header-navlink1">
            <div className="header-container2">
              <img
                alt={props.imageAlt1}
                src={props.imageSrc1}
                loading="lazy"
                className="header-image1"
              />
              <span className="header-text1 Large">AIPLY.AI</span>
            </div>
          </Link>
          <div className="header-menu1">
            <Link to="/about-us" className="header-navlink2 Large">
              About us
            </Link>
            <a
              href="https://blog.aiply.ai"
              target="_blank"
              rel="noreferrer noopener"
              className="header-link1 Large"
            >
              Blog
            </a>
            <Link to="/contact-us" className="header-navlink3 Large">
              Contact Us
            </Link>
          </div>
          <div className="header-container3">
            <a
              href="https://app.aiply.ai"
              target="_blank"
              rel="noreferrer noopener"
              className="header-link2"
            >
              <div className="header-container4">
                <PrimaryPinkButton
                  button="buy now"
                  button1="try now"
                  button2="try now"
                ></PrimaryPinkButton>
              </div>
            </a>
            <div data-role="BurgerMenu" className="header-burger-menu">
              <svg viewBox="0 0 1024 1024" className="header-icon1">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
          </div>
        </div>
      </nav>
      <div data-role="MobileMenu" className="header-mobile-menu">
       
          <div className="header-top">
            <div className="header-container5">
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                loading="lazy"
                className="header-image2"
              />
               <Link to="/">
              <span className="header-link3">
                {props.link ?? (
                  <Fragment>
                    <span className="header-text2">
                      <span className="Large">AIPLY.AI</span>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </span></Link>
            </div>
            <div data-role="CloseMobileMenu" className="header-close-menu">
              <svg viewBox="0 0 1024 1024" className="header-icon3">
                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
              </svg>
            </div>
          </div>
        
        <div className="header-mid">
          <div className="header-menu2">
            <Link to="/about-us" className="header-navlink5 Large">
              About us
            </Link>
            <a
              href="https://blog.aiply.ai"
              target="_blank"
              rel="noreferrer noopener"
              className="header-link4 Large"
            >
              Blog
            </a>
            <Link to="/contact-us" className="header-navlink6 Large">
              Contact us
            </Link>
          </div>
        </div>
        <a
          href="https://app.aiply.ai"
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="header-bot">
            <PrimaryPinkButton
              button="buy now"
              button2="try now"
            ></PrimaryPinkButton>
          </div>
        </a>
      </div>
    </div>
  )
}

Header.defaultProps = {
  rootClassName: '',
  imageAlt: 'aiply.ai logo',
  imageSrc: '/logos/aiply_icon.svg',
  imageSrc1: '/logos/aiply_icon.svg',
  link: undefined,
  imageAlt1: 'aiply.ai logo',
}

Header.propTypes = {
  rootClassName: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  imageSrc1: PropTypes.string,
  link: PropTypes.element,
  imageAlt1: PropTypes.string,
}

export default Header
