import React from 'react'

import PropTypes from 'prop-types'

import FeatureCard from './feature-card'
import './features.css'

const Features = (props) => {
  return (
    <section id={props.featuresId} className="features-features">
      <FeatureCard
        text="Harness the power of AI to conduct seamless technical interviews that evaluate candid accurately and efficiently"
        title="Components"
        newProp="AI-powered interviewing"
        imageSrc="/Assets/Icons/brain-1--streamline-ultimate1.svg"
      ></FeatureCard>
      <FeatureCard
        text="Test for problem-solving, and analytical skills with our advanced evaluation algorithms tailored to your needs"
        newProp="Comprehensive skill assessment"
        imageSrc="/Assets/Icons/pie-line-graph-desktop--streamline-ultimate1.svg"
      ></FeatureCard>
      <FeatureCard
        text="Receive instant, data-driven insights and feedback on candidate performance, enabling faster decision-making"
        title="Less Code"
        newProp="Real-time feedback"
        imageSrc="/Assets/Icons/space-rocket-flying--streamline-ultimate1.svg"
      ></FeatureCard>
      <FeatureCard
        text="Easily scale your interview process to meet the demands of your growing team with automated scheduling and assessments"
        title="Fully Responsive"
        newProp="Scalable solutions"
        imageSrc="/Assets/Icons/browser-page-hierarchy--streamline-ultimate1.svg"
      ></FeatureCard>
    </section>
  )
}

Features.defaultProps = {
  featuresId: '',
}

Features.propTypes = {
  featuresId: PropTypes.string,
}

export default Features
