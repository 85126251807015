import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './style.css';
import ScrollToTop from './components/ScrollToTop'; // Import ScrollToTop component
import LogRocket from 'logrocket';
LogRocket.init('yyoujr/aiplyai');

const resizeObserverLoopErr = "ResizeObserver loop limit exceeded";
window.addEventListener("error", (e) => {
  if (e.message === resizeObserverLoopErr) {
    e.stopImmediatePropagation();
  }
});

// Lazy load route components
const AboutUs = lazy(() => import('./views/about-us'));
const ContactUs = lazy(() => import('./views/contact-us'));
const Home = lazy(() => import('./views/home'));
const PrivacyPolicy = lazy(() => import('./views/privacy-policy'));
const TermsOfUse = lazy(() => import('./views/terms-of-use'));
const NotFound = lazy(() => import('./views/not-found'));

const App = () => {
  return (
    <Router>
      <ScrollToTop /> {/* Add the ScrollToTop component here */}
      <Suspense fallback={<div>Loading...</div>}> {/* Display this while loading */}
        <Switch>
          <Route component={AboutUs} exact path="/about-us" />
          <Route component={ContactUs} exact path="/contact-us" />
          <Route component={Home} exact path="/" />
          <Route component={PrivacyPolicy} exact path="/privacy-policy" />
          <Route component={TermsOfUse} exact path="/terms-of-use" />
          <Route component={NotFound} path="*" />
        </Switch>
      </Suspense>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
